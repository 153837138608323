// Copyright (C) 2019 Checkmk GmbH - License: Check_MK Enterprise License
// This file is part of Checkmk (https://checkmk.com). It is subject to the terms and
// conditions defined in the file COPYING, which is part of this source code package.

//#.
//#   .-Designer--------------------------------------------------------------.
//#   |               ____            _                                       |
//#   |              |  _ \  ___  ___(_) __ _ _ __   ___ _ __                 |
//#   |              | | | |/ _ \/ __| |/ _` | '_ \ / _ \ '__|                |
//#   |              | |_| |  __/\__ \ | (_| | | | |  __/ |                   |
//#   |              |____/ \___||___/_|\__, |_| |_|\___|_|                   |
//#   |                                 |___/                                 |
//#   +-----------------------------------------------------------------------+
//#   |  Code for the interactive graph designed                              |
//#   '-----------------------------------------------------------------------'

function count_graph_designer_checked_checkbox() {
    let all_checkboxes = document.querySelectorAll("#form_graph input[type='checkbox']");
    return Array.from(all_checkboxes).filter(el => el.checked).length;
}

export function fix_graph_designer_operator_visibiliy() {
    toggle_graph_designer_block_visibility(
        "graph_designer_operators",
        count_graph_designer_checked_checkbox() >= 2
    );
}

export function fix_graph_designer_transform_visibiliy() {
    toggle_graph_designer_block_visibility(
        "graph_designer_transformations",
        count_graph_designer_checked_checkbox() == 1
    );
}

export function toggle_graph_designer_block_visibility(elementid, visible) {
    let block = document.getElementById(elementid);
    let block_off = document.getElementById(elementid + "_off");
    if (visible) {
        block.style.display = "";
        block_off.style.display = "none";
    } else {
        block.style.display = "none";
        block_off.style.display = "";
    }
}
