import * as cmk_figures from "cmk_figures";
import * as d3Hexbin from "d3-hexbin";

export class HostStateSummary extends cmk_figures.TextFigure {
    ident() {
        return "host_state_summary";
    }

    render() {
        const summary_data = this._data.data;
        if (!summary_data.in_state || !summary_data.total) return;
        this.render_title(this._data.title, this._data.title_url);

        const text =
            summary_data.in_state.count.toString() + "/" + summary_data.total.count.toString();
        const value_font_size = Math.min(
            this.plot_size.width / text.length,
            (this.plot_size.height * 2) / 3
        );
        cmk_figures.metric_value_component(this.plot, {
            value: {
                value: text,
                url: summary_data.in_state.url,
            },
            ...cmk_figures.metric_value_component_options_big_centered_text(this.plot_size, {
                font_size: value_font_size,
            }),
        });
    }
}

export class ServiceStateSummary extends HostStateSummary {
    ident() {
        return "service_state_summary";
    }
}

cmk_figures.figure_registry.register(HostStateSummary);
cmk_figures.figure_registry.register(ServiceStateSummary);
