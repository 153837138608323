import * as cmk_figures from "cmk_figures";

class StateFigure extends cmk_figures.TextFigure {
    ident() {
        return "state_service";
    }

    render() {
        const plot = this._data.plot_definitions[0];
        if (!plot) {
            // rendering before the first ajax call
            return;
        }

        this.render_title(this._data.title, this._data.title_url);

        const svc_status_display = cmk_figures.getIn(plot, "status_display");
        const is_ok_status = (cmk_figures.getIn(svc_status_display, "css") || "").endsWith("0");

        const background_status_cls = cmk_figures.svc_status_css("background", svc_status_display);
        const label_paint_style = cmk_figures.getIn(svc_status_display, "paint");
        const label_status_cls = cmk_figures.svc_status_css(label_paint_style, svc_status_display);
        const summary_visible =
            !is_ok_status && cmk_figures.getIn(svc_status_display, "summary") === "not_ok";

        cmk_figures.background_status_component(this.plot, {
            size: this.plot_size,
            css_class: background_status_cls,
            visible: background_status_cls !== "",
        });

        const margin = 15;
        let font_size = 14; // defined in cmk_figures.state_component

        // potential long summary text starting at the top of dashlet
        let summary_data = summary_visible ? this._data.data : [];
        const margin_bottom = font_size * 2; // space for label at the bottom

        // metric_value_component creates a href, so we want to link the label and status text, too:
        this.plot
            .selectAll("a.href")
            .data(summary_data)
            .join("a")
            .classed("href", true)
            .attr("xlink:href", d => d.url || null)
            .selectAll("foreignObject")
            .data(d => [d])
            .join("foreignObject")
            .style("position", "relative")
            .attr("x", margin)
            .attr("y", this.plot_size.height * 0.5 + margin_bottom)
            .attr("width", this.plot_size.width - margin * 2)
            .attr("height", this.plot_size.height * 0.5 - margin_bottom * 2)
            .selectAll("div")
            .data(d => [d])
            .join("xhtml:div")
            .style("position", "absolute")
            .style("bottom", 0)
            .style("width", "100%")
            .style("font-size", font_size + "px")
            .style("text-align", "center")
            .style("overflow-wrap", "break-word") // break long words
            .text(d => d.plugin_output);

        // big short status display center of dashlet
        cmk_figures.metric_value_component(this.plot, {
            value: {
                value: svc_status_display.msg,
                url: this._data.data[0].url,
            },
            ...cmk_figures.metric_value_component_options_big_centered_text(this.plot_size, {}),
        });
    }
}

class StateHostFigure extends StateFigure {
    ident() {
        return "state_host";
    }
}

cmk_figures.figure_registry.register(StateFigure);
cmk_figures.figure_registry.register(StateHostFigure);
